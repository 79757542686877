import React, { useState } from 'react'
import { Form, Input, Button, Alert, message } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import Auth from '@/services/Auth'
import './login.less'
import { useHistory } from 'react-router-dom'
import logo from '@/assets/images/OxBox.png'
import parseQuery from '../../prototypes/parseToQuery'
// console.log(Auth)
function Reset() {
  const history = useHistory()
  const [notfound, setNotFound] = useState(false)
  const [isloading, setIsloading] = useState(false)

  const onFinish = (values) => {
    console.log(values)
    if (!values.password) return message.error('Пожалуйста, укажите поле пароля')
    setIsloading(true)
    Auth.updatePassoword({ ...values, token: parseQuery().token }).then(res => {
      // console.log(res)
      message.success('Пароль обновлен успешно')
        history.push('/')
    }).catch(err => {
      // console.log(err)
    }).finally(() => setIsloading(false))
  }

  return (
    <div className='login' style={{ transaction: 'all 2s'}}>
      <div className='logo_content'>
        <img
          className='logo_image'
          alt={'Logo'}
          src={logo}
        />
        <h1 style={{ fontSize: '32px', fontWeight: '500' }}>ПРОФЕССИОНАЛЬНАЯ ПЛАТФОРМА ДЛЯ ИССЛЕДОВАНИЙ</h1>
      </div>
      <div className='login_content'>
        <h1>Установка пароля</h1>
        { notfound ? (<Alert style={{ marginBottom: '20px' }} message="Логин или пароль неверный !" type="error" />) : undefined }
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Пожалуйста, введите ваше имя пользователя!' }]}
          >
            <Input
              spellCheck="false"
              size="large"
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Введите пароль'
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              loading={isloading}
              htmlType='submit'
              block
            //   className='login-form-button'
              size='large'
            >
              Отправить
            </Button>
            {/*Or <a href="">register now!</a>*/}
          </Form.Item>
        </Form>
        <p>Все права защищены. &copy; Udevs {new Date().getFullYear()}</p>
      </div>
    </div>
  )
}
export default Reset
