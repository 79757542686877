const initialQuestionState = {
  stack: []
}

const stackReducer = (state = initialQuestionState, action) => {
  const { payload } = action
  // console.log(payload)
  // if (action.type === 'REMOVE_STACK') {
  //   console.log(payload)
  // }
  switch (action.type) {
    case 'SET_STACK':
      return {
        ...state,
        stack: payload,
      }

    case 'ADD_STACK':
      return {
        ...state,
        stack: [...state.stack, payload],
      }

    case 'POP_STACK':
      return {
        ...state,
        stack: state.stack.map((sk) =>
        sk.id === payload.id ? payload : sk
        ),
      }
    case 'REMOVE_STACK':
      return {
        ...state,
        stack: payload,
      }
    case 'CLEAR_STACK':
      return {
        ...state,
        stack: [],
      }
    default:
      return state
  }
}

export default stackReducer
