// Dependencies list
// import '@/App.less'
// Components list
// import Navbar from '../../flow/navbar/navbar.jsx'

export default function Home(props) {
  return (
    <div style={{ background: '#f9f9f9', width: '100%', height: '100%' }}>
      {props.children}
    </div>
  )
}
