import routes from "../constants/router"
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom"
import Login from "../pages/Login/Login"
import ResetPassword from "../pages/Login/ResetPassword"
import PageNotFound from "@/pages/statuses/404"
import { useSelector } from 'react-redux'
import React, { Suspense } from "react"
// console.log(routes)
import Fallback from '@/components/loading/index.jsx'
function Router() {
    const accessToken = useSelector(el => el.auth.accessToken)
    const UserId = useSelector(el => el.auth.user_role_id)
    // const accessToken = true
    function Loader({ path, children }) {
        const Component = path
        // console.log(children)
        return (
            <Suspense fallback={<Fallback />}>
                <Component>{children}</Component>
            </Suspense>
        )
    }
    const privateRoutes =  ['/home/users', '/home/superusers' , '/home/operators', '/home/panel-list', '/home/notifications']
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/reset-password" component={ResetPassword} />
                {
                    accessToken
                    ? <Route exact path="/"><Redirect from="/" to="/home/projects" /></Route> 
                    : <Redirect to="/login" />
                }
            
                {
                    UserId !== process.env.SUPER_USER_ROLE_ID   ? 
                    privateRoutes.map((route, index) => (
                 <Route exact path={route} key={index}><Redirect from={route} to="/home/projects" /></Route>               
                    ))              
                    : UserId
                }

                {accessToken ? routes.map((route, index) => (
                    <Route
                        exact={!route.children.length}
                        key={index}
                        path={route.path}
                    >
                        <route.component>
                            {
                                route.children.map((subRoute, index) => (
                                    <Route
                                        exact={!subRoute?.children?.length}
                                        key={`${index + 101}`}
                                        path={`${route.path}${subRoute.path}`}
                                    >
                                        <Loader path={subRoute.component} />
                                    </Route>
                                ))
                            }
                        </route.component>
                    </Route>
                )) :<Redirect to="/login" />
                }
                <Route path="/404" component={PageNotFound} />
                <Redirect from="*" to="/404" />
            </Switch>
        </BrowserRouter>
    );
}

export default Router;
