export const SET_AUTH_CREDENTIALS = `SET_AUTH_CREDENTIALS`;
export const SET_AUTH_TOKENS = `SET_AUTH_TOKENS`;
export const SET_PROFILE_IMAGE = `SET_PROFILE_IMAGE`;
export const REFRESH_ACCESS_TOKEN = `REFRESH_ACCESS_TOKEN`;

export const CLEAR_ON_SIGNOUT = `CLEAR_ON_SIGNOUT`;
export const CHANGE_LANGUAGE = `CHANGE_LANGUAGE`;

export const IS_LOADING = "IS_LOADING";
export const MENU_KEY = "MENU_KEY";
