import logo from '@/assets/images/survey.svg'
const basic = {
    PRIMARY_COLOR: 'var(--primary-color)',
    TITLE: 'Oxbox',
    MENU_THEME: 'light',
    COMPANY_NAME: 'Udevs',
    LOGO: logo,
    USER_LOGO: 'https://i.pinimg.com/originals/93/55/a5/9355a58dd77f8dcd1df4cdf2097af3aa.gif',
    BASE_URL: 'http://api.manager.oxbox.uz/v1'
}
export default basic
