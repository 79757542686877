import request from '@/utils/axios'

const req = {
    login: (data) => request({ method: 'post', url: '/auth/login/system-user', data }),
    loginBySuperUser: (data) => request({ method: 'post', url: '/auth/login/super-user', data }),
    logout: (data) => request({ method: 'post', url: '/auth/logout', data }),
    refreshToken: (data) => request({ method: 'post', url: '/auth/token/refresh', data }),
    resetPasswordByEmail: (data) => request({ method: 'post', url: '/auth/send-to-url', data }),
    updatePassoword: (data) => request({ method: 'put', url: '/auth/user_password', data })
}
export default req