import axios from 'axios'
import { notification } from 'antd'
// import { useHistory } from 'react-router-dom'
const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 12000,
})

const errorHandler = (error, hooks) => {
  if (error.response) {
    const data = error.response.data
    console.log(error.response)
    // const token = localStorage.getItem('token')
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }

    if ( error.response.status === 401) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      localStorage.removeItem('token')
      localStorage.removeItem('persist:dashboard')
      window.location.href = '/'
    }
  }
  return Promise.reject(error.response)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  config.headers['client-id'] = process.env.CLIENT_ID
  if (token) {
    config.headers['Authorization'] = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)


export default request

