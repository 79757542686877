import {
  SET_AUTH_CREDENTIALS,
  SET_AUTH_TOKENS,
  CLEAR_ON_SIGNOUT,
  IS_LOADING,
  SET_PROFILE_IMAGE,
} from "../constants";

const INITIAL_STATE = {
  phoneNumber: "",
  accessToken: "" || localStorage.getItem("token"),
  refreshToken: "",
  isLoading: false,
};

export default function authReducer(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case SET_PROFILE_IMAGE:
      let userInfo = JSON.parse(state?.user_info) || { image: null };

      userInfo.image = payload || {
        uuid: "",
        url: "",
      };

      userInfo = JSON.stringify(userInfo);
      return {
        ...state,
        user_info: userInfo,
      };
    // return {
    //   ...state,
    //   isLoading: payload,
    // };
    case IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_AUTH_CREDENTIALS:
      return {
        ...state,
        phoneNumber: payload,
      };
    case SET_AUTH_TOKENS:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        user_id: payload.user_id,
        user_info: payload.user_info,
        user_role_id: payload.user_role_id,
      };
    case CLEAR_ON_SIGNOUT:
      return {
        ...state,
        accessToken: "",
        refreshToken: "",
      };
    default:
      return state;
  }
}
