import { useState } from "react"
import Auth from '../../services/Auth'
import { message, Modal, Input, Button } from 'antd'

export default function Reset () {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    
    const openDialog = () => {
        console.log('hello')
        setIsModalVisible(true)
      }
    
    const sentEmail = () => {
        if (!email) return message.error('Пожалуйста, укажите свой адрес электронной почты')
        setLoading(true)
        Auth.resetPasswordByEmail({ email }).then(res => {
          message.success('Письмо отправлено. Пожалуйста, поставьте отметку в вашем поле')
          setIsModalVisible(false)
        }).finally(e => setLoading(false))
    }
    
    return (
        <>
        <span onClick={openDialog}><a>Забыли пароль ?</a></span>
         <Modal 
            title="Пожалуйста, укажите свой адрес электронной почты"
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={[
                <Button key="back" onClick={() => setIsModalVisible(false)}>
                  Отмена
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={sentEmail}>
                  Отправить
                </Button>,
            ]}>
            <label>Адрес электронной почты</label>
            <Input placeholder="электронной почты" value={email} onChange={e => setEmail(e.target.value)}></Input>
        </Modal>
        </>
    )
}